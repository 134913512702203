import { Dialog } from 'primereact/dialog';
import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import GoogleCallback from './pages/GoogleCallback';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import './App.scss';

import menu from './menu';
import routes from './routes';
import { QrScaner } from './utilities/QrScaner';

// const appName = process.env.REACT_APP_NAME;

const App = () => {
    const layoutMode = useState('slim');

    //const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    //const [inlineUserMenuActive, setInlineUserMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [showQrScaner, setShowQrScaner] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    const navigate = useNavigate();

    // set focus on target input
    useEffect(() => {
        //setBins();
        //setCities();
        // setLocations();
    }, []);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        // if (AuthService.isLoggedin() === false) {
        //     navigate('/login');
        // }
    }, [navigate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        } else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const hideOverlayMenu = () => {
        //setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }
        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
        //setInlineUserMenuActive(false);
    };

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    };

    const isSlim = () => {
        return layoutMode === 'slim';
    };

    return (
        <div className="relative flex flex-column min-h-screen">
            <Dialog header="Header" visible={showQrScaner} style={{ width: '50vw' }} onHide={() => setShowQrScaner(false)}>
                <QrScaner></QrScaner>
            </Dialog>

            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} layoutMode={layoutMode} active={menuActive} mobileMenuActive={staticMenuMobileActive} />

            <div className="layout-main h-auto flex-grow-1 ">
                <div className="layout-content  ">
                    <Routes>
                        <Route path="/google" element={<GoogleCallback />}></Route>
                        {/* load routest from route.js file */}
                        {routes.map((route, index) => (
                            <Route key={route.path} path={route.path} element={route.element} />
                        ))}
                    </Routes>
                </div>
            </div>
            <AppFooter />
        </div>
    );
};

export default App;
