import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_URL,
    withCredentials: true,
    //withXSRFToken: true
    // set headers here with token from local storage
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
});

// interceptors from axios handle 401 errors
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem('is-user-loged-id');
            window.location.href = '/login';
        }

        return Promise.reject(error);
    }
);

//  if 422 error, show error message
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 422) {
            return Promise.reject(error.response.data);
        }
    }
);

export default api;
