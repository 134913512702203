import React from 'react';
import { Link } from 'react-router-dom';

const AppFooter = () => {
    return (
        <div className="absolite  bottom-0  px-2 md:px-4 lg:px-6 py-4 flex flex-column sm:flex-row align-items-center justify-content-between " style={{ backgroundColor: '#006E3D' }}>
            <div className="flex flex-nowap  align-items-center font-medium pb-2 ">
                <Link to={'/about'}>
                    <div className="p-ripple cursor-pointer block text-white py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900">
                        O NAMA<span role="presentation" className="p-ink"></span>
                    </div>
                </Link>
                <Link to={'/locations'}>
                    <div className="p-ripple cursor-pointer block text-white py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900">
                        LOKACIJE<span role="presentation" className="p-ink"></span>
                    </div>
                </Link>
                <Link to={'/questionnaires'}>
                    <div className="p-ripple cursor-pointer block text-white py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900">
                        ANKETA<span role="presentation" className="p-ink"></span>
                    </div>
                </Link>
            </div>
            <div className="flex flex-nowap  align-items-center font-medium ">
                <Link to={'/'}>
                    <div className="border-circle  mr-3 w-4rem h-4rem bg-white font-bold flex align-items-center justify-content-center">
                        <img src="assets/logo_full.svg" alt="footer sections" width={40} height={40} className="" />
                    </div>
                </Link>
                <a href="https://www.facebook.com/institutzaregionalnirazvoj/?locale=sr_RS">
                    <div className="border-circle  mr-3 w-4rem h-4rem bg-white font-bold flex align-items-center justify-content-center">
                        <i className="pi pi-facebook" style={{ fontSize: '2rem' }}></i>
                    </div>
                </a>
                <a href="https://www.youtube.com/channel/UCpdvPYBQWjY54okWsAapzGQ">
                    <div className="border-circle  mr-3 w-4rem h-4rem bg-white font-bold flex align-items-center justify-content-center">
                        <i className="pi pi-youtube" style={{ fontSize: '2rem' }}></i>
                    </div>
                </a>
                <a href="https://www.instagram.com/institut_za_regionalni_razvoj/?ref=nl-rep-a-scl&hl=en">
                    <div className="border-circle  mr-3 w-4rem h-4rem bg-white font-bold flex align-items-center justify-content-center">
                        <i className="pi pi-instagram" style={{ fontSize: '2rem' }}></i>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default AppFooter;
