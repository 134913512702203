import api from '../auth';
const slug = 'api/changes';

const ChangesService = () => {
    const getChangess = () => {
        return api.get(slug).then((res) => res.data);
    };

    const getChangessLazy = (params) => {
        return api
            .get(slug, {
                params
            })
            .then((res) => res.data);
    };

    const getChanges = (id) => {
        return api.get(slug + `/show/${id}`).then((res) => res.data);
    };

    const deleteChanges = (id) => {
        return api
            .delete(slug + `/change/${id}`)
            .then((res) => res.data)
            .catch((err) => {
                return err;
            });
    };

    const createChanges = (data) => {
        return api.post(slug, data).then((res) => res.data);
    };

    const updateChanges = (id, data) => {
        return api.put(slug + `/change/${id}`, data).then((res) => res.data);
    };

    const getOptions = () => {
        return api.options(slug).then((res) => res.data.actions.POST);
    };

    // custom action
    const copyChanges = (id) => {
        return api.post(slug + `/copy/${id}`).then((res) => res.data);
    };

    return {
        getChangess,
        getChangessLazy,
        getChanges,
        deleteChanges,
        createChanges,
        updateChanges,
        getOptions,
        copyChanges
    };
};

export default ChangesService;
