import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'primereact/button';

// Import LocationsService for CRUD operations
import LocationsService from '../../service/LocationsService';

// Import all related models

// Import related store

import * as yup from 'yup';
import i18n from '../../i18n';
import { useYupValidationResolver } from '../../utilities/useYupValidationResolver';

/* Validation schema */
const validationSchema = yup.object().shape({
    naziv: yup
        .string()
        .required(i18n.t('required'))
        .max(55, i18n.t('validations.must_be_at_most', { model: 'Naziv', characters: '50' })),

    location_name: yup.string().required('location_name is required.'),
    coordinates: yup.string().required('coordinates is required.'),
    address: yup.string().required('address is required.'),
    city_id: yup.string().required('city_id is required.')
});
// translation with parameters

// Welcome, John, Admin

function LocationsForm() {
    const [model, setModel] = useState([]);

    const navigate = useNavigate();

    const { id } = useParams(); // Uzimanje parametara iz URL-a

    // set default values for form
    const defaultValues = {
        location_name: '',
        coordinates: '',
        address: '',
        city_id: ''
    };

    // related store hooks

    const { getLocations } = LocationsService();

    const resolver = useYupValidationResolver(validationSchema);

    const { reset, setFocus } = useForm({ defaultValues, resolver });

    // set focus on target input
    useEffect(() => {
        setFocus('naziv');
    }, [setFocus]);

    useEffect(() => {
        if (id) {
            getLocations(id).then((res) => {
                setModel(res.data);
                reset(res.data);
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const createIframe = (coordinates, address, city) => {
        return `https://maps.google.com/maps?q=${coordinates}&t=&z=15&ie=UTF8&iwloc=&output=embed`;
    };

    return (
        <div className="form-locations">
            <div className="p-2">
                <li className="flex align-items-center py-3 px-2 surface-ground">
                    <div className="text-500 w-full md:w-2 font-medium"> {i18n.t('locations.location_name')} </div>
                    <div className="text-900 w-full md:w-10"> {model.location_name} </div>
                </li>
                <li className="flex align-items-center py-3 px-2">
                    <div className="text-500 w-full md:w-2 font-medium">{i18n.t('locations.location_address')}</div>
                    <div className="text-900 w-full md:w-10 line-height-3">{model.address}</div>
                </li>
                <li className="flex align-items-center py-3 px-2 surface-ground">
                    <div className="text-500 w-full md:w-2 font-medium">{i18n.t('locations.location_city')}</div>
                    <div className="text-900 w-full md:w-10 line-height-3">{model.city_id}</div>
                </li>
                <li className="flex align-items-center py-3 px-2 ">
                    <div className="text-500 w-full md:w-2 font-medium">{i18n.t('locations.bin_count')}</div>
                    <div className="text-900 w-full md:w-10 line-height-3">{model.bins}</div>
                </li>
            </div>

            <div className="surface-section px-4  md:px-6 lg:px-8">
                <div className="grid ">
                    {/* list bins_list */}
                    {model.bins_list &&
                        Object.keys(model.bins_list).map((key, index) => {
                            return (
                                <div key={index} className="col-12 md:col-6 xl:col-4 p-3">
                                    <div className="surface-card shadow-2 border-round p-4">
                                        <div className="flex surface-border pb-4">
                                            <div className="flex flex-column align-items-start">
                                                <span className="text-lg text-900 font-medium mb-1">Kanta za odlaganje #{model.bins_list[key]}</span>
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between pt-4">
                                            <Button
                                                label={i18n.t('locations.show')}
                                                icon="pi pi-map"
                                                className=" w-full font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised white-space-nowrap"
                                                onClick={() => {
                                                    navigate(`/changes/${key}/new`);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            <div style={{ width: '100%' }}>
                <iframe title="Google Maps 2" src={createIframe(model.coordinates, model.address, model.city_id)} width="100%" height="300" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
}

export default LocationsForm;
