import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import { classNames } from 'primereact/utils';
// Import ChangesService for CRUD operations
import ChangesService from '../../service/ChangesService';

// Import all related models

// Import related store

import * as yup from 'yup';
import { useAuth } from '../../hooks/auth-provider';
import i18n from '../../i18n';
import { useYupValidationResolver } from '../../utilities/useYupValidationResolver';

/* Validation schema */
const validationSchema = yup.object().shape({
    bin_id: yup.string().required('bin_id is required.'),
    input: yup.number('Ulaz mora biti broj.').min(0.0001, 'Output mora biti veći od 0.').required('Ulaz je obavezno polje.')
});
// translation with parameters

// Welcome, John, Admin

function ChangesForm() {
    //const [model, setModel] = useState([]);
    const [showForm, setShowForm] = useState(true);

    const toast = useRef(null);
    const navigate = useNavigate();

    const { id } = useParams(); // Uzimanje parametara iz URL-a
    const { isLoggedin } = useAuth();

    // set default values for form
    const defaultValues = {
        bin_id: id,
        // user_id: '',
        input: 2,
        output: 0
    };

    // related store hooks

    const { createChanges } = ChangesService();

    const resolver = useYupValidationResolver(validationSchema);

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setFocus
    } = useForm({ defaultValues, resolver });

    // set focus on target input
    useEffect(() => {
        setFocus('naziv');
    }, [setFocus]);

    const onSubmit = (data) => {
        createChanges(data).then(
            (resolve) => {
                // setModel(resolve.data);
                reset(resolve.data);
                toast.current.show({ severity: 'success', summary: i18n.t('success'), detail: i18n.t('success_create'), life: 3000 });
                // navigate('/changes/' + resolve.data.id + '/edit');
                setShowForm(false);
            },
            (reject) => {
                // foreach errors and show toast
                reject.errors.forEach((error) => {
                    toast.current.show({ severity: 'error', summary: i18n.t('error'), detail: error, life: 3000 });
                });
            }
        );
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    const cancelEdit = () => {
        navigate('/');
    };

    return (
        <div className="p-4">
            <Toast ref={toast} position="top-center" />

            {/* showForm if true */}
            {showForm && (
                <div className="grid">
                    <div className="col-12 ">
                        <span className="text-900 text-2xl font-medium mb-4 block">{i18n.t('changes.input_change')}</span>
                        <form onSubmit={handleSubmit(onSubmit)} className="">
                            <div className="field p-fluid">
                                <label htmlFor="input" className={classNames({ 'p-error': errors.input })}>
                                    {i18n.t('changes.input')}
                                    <span className="text-pink-500 ml-2">*</span>
                                </label>
                                <div className="p-inputgroup flex-1">
                                    <Controller
                                        name="input"
                                        control={control}
                                        rules={{ required: 'input is required.' }}
                                        render={({ field, fieldState }) => <InputText id={field.name} {...field} type="text" className="p-inputtext-lg" placeholder="u litrima" />}
                                    />
                                    <span className="p-inputgroup-addon">Litara</span>
                                </div>
                                {getFormErrorMessage('input')}
                            </div>

                            <div className="surface-border opacity-50 mb-3 col-12"></div>
                            {isLoggedin() && (
                                <div className="flex justify-content-between flex-wrap">
                                    <Button icon="pi pi-save" className="p-button-rounded  px-5 py-3 p-button-raised" label={id ? i18n.t('changes.input_button') : i18n.t('add')} type="submit" />

                                    {id && <Button icon="pi pi-chevron-left" label={i18n.t('cancel')} type="button" className="p-button-rounded  px-5 py-3 p-button-raised  mr-1" severity="warning" onClick={() => cancelEdit()} />}
                                </div>
                            )}
                            {!isLoggedin() && (
                                <div className=" ">
                                    <div>
                                        <div className="flex align-items-start mb-3 p-4 bg-green-100 border-round border-1 border-green-300">{i18n.t('login_to_continue')}</div>
                                    </div>
                                    <div className="flex justify-content-between flex-wrap">
                                        <Link to="/login" className=" p-button-text text-green-900 hover:text-green-700">
                                            <Button icon="pi pi-user" className="w-full p-button-rounded  px-5 py-3 p-button-raised" label={i18n.t('login')} type="submit" />
                                        </Link>
                                        <Link to="/register" className=" text-green-900 hover:text-green-700">
                                            <Button icon="pi pi-user-plus" className="w-ful p-button-text  p-button-rounded  px-5 py-3 p-button-raised" label={i18n.t('register')} type="submit" />
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            )}
            {/* show message if showForm is false */}
            {!showForm && (
                <div>
                    <div className="grid pt-2">
                        <Link to="/locations" className="p-button-text text-green-900 hover:text-green-700">
                            <div className="flex align-items-start m-3 p-4 bg-green-100 border-round border-1 border-green-300">
                                <i className="pi pi-check-circle text-green-900 text-2xl mr-3"></i>
                                <div className="mr-3">
                                    <div className="text-green-900 font-medium text-xl mb-2 line-height-1">Bravo!</div>
                                    <p className="m-0 p-0 text-green-700 line-height-3">Upravo ste učinili korak ka boljoj budućnosti recikliranjem jestivog ulja.</p>
                                </div>
                            </div>
                        </Link>
                        {/* dugme ya pogratak na po;etni ekran */}
                    </div>
                    <div className="mt-4 mr-0 md:mt-0 md:mr-0">
                        <Button label={i18n.t('back_to_home')} onClick={() => cancelEdit()} className="w-full font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised white-space-nowrap" />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ChangesForm;
