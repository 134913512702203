import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationSR from './locales/sr/translation.json';
import translationSRLAT from './locales/sr-latn/translation.json';

// npm install i18next-http-backend i18next-browser-languagedetector --save
//import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// import AuthService from './service/AuthService';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const resources = {
    'sr-latn': {
        translation: translationSRLAT
    },
    en: {
        translation: translationEN
    },
    sr: {
        translation: translationSR
    }
};

const calendarTran = {
    'sr-latn': {
        firstDayOfWeek: 1,
        dayNames: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
        dayNamesShort: ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'],
        dayNamesMin: ['Ne', 'Po', 'Ut', 'Sr', 'Če', 'Pe', 'Su'],
        monthNames: ['januar', 'februar', 'mart', 'april', 'maj', 'jun', 'jul', 'avgust', 'septembar', 'octobar', 'novembar', 'decembar'],
        monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'avg', 'sep', 'oct', 'nov', 'dec'],
        today: 'Danas',
        clear: 'Očisti',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Ned'
    },
    en: {
        firstDayOfWeek: 1,
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'avg', 'sep', 'oct', 'nov', 'dec'],
        today: 'Today',
        clear: 'Clear',
        dateFormat: 'dd / mm / yy',
        weekHeader: 'Sun'
    },
    sr: {
        firstDayOfWeek: 1,
        dayNames: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
        dayNamesShort: ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'],
        dayNamesMin: ['Ne', 'Po', 'Ut', 'Sr', 'Če', 'Pe', 'Su'],
        monthNames: ['januar', 'februar', 'mart', 'april', 'maj', 'jun', 'jul', 'avgust', 'septembar', 'octobar', 'novembar', 'decembar'],
        monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'avg', 'sep', 'oct', 'nov', 'dec'],
        today: 'Danas',
        clear: 'Očisti',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Ned'
    }
};

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //.use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: 'sr', // AuthService.getLng(),
        debug: false,
        lng: 'sr', //AuthService.getLng(),
        calendarTran,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;
