// Import UserService for CRUD operations
// Import all related models

import React from 'react';
import { useAuth } from '../hooks/auth-provider';

export const Profile = () => {
    const { getCurrentUser } = useAuth();

    const { id, name, email } = getCurrentUser();

    return (
        <div className="card">
            <h1>Moj profil</h1>
            <p>Korisnički ID : #{id}</p>
            <p>Ime: {name}</p>
            <p>Email: {email}</p>
        </div>
    );
};
