const menu = [
    {
        label: 'Locations',
        icon: 'pi pi-fw pi-home',
        to: '/locations'
    },
    {
        label: 'Questionnaires',
        icon: 'pi pi-fw pi-home',
        to: '/questionnaires'
    }
];
export default menu;
