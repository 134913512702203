import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { StyleClass } from 'primereact/styleclass';
import { classNames } from 'primereact/utils';
import React, { createRef, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useAuth } from './hooks/auth-provider';
import i18n from './i18n';

const AppSubmenu = forwardRef((props, ref) => {
    const [activeIndex, setActiveIndex] = useState(null);

    // add last item to props.model

    const onMenuItemClick = (event, item, index) => {
        if (item.disabled) {
            event.preventDefault();
            return;
        }
        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
            event.preventDefault();
        }
        if (item.items) {
            event.preventDefault();
        }
        if (props.root) {
            props.onRootMenuItemClick({
                originalEvent: event
            });
        }
        if (item.items) {
            setActiveIndex(index === activeIndex ? null : index);
        }

        props.onMenuItemClick({
            originalEvent: event,
            item: item
        });
    };

    const onMenuItemMouseEnter = (index) => {
        if (props.root && props.menuActive && isHorizontalOrSlim() && !isMobile()) {
            setActiveIndex(index);
        }
    };

    const isMobile = () => {
        return window.innerWidth <= 1025;
    };

    const isHorizontalOrSlim = useCallback(() => {
        return props.layoutMode === 'horizontal' || props.layoutMode === 'slim';
    }, [props.layoutMode]);

    const isSlim = useCallback(() => {
        return props.layoutMode === 'slim';
    }, [props.layoutMode]);

    const visible = (item) => {
        return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
    };

    const getLink = (item, index) => {
        //const menuitemIconClassName = classNames('layout-menuitem-icon', item.icon);
        const content = (
            <>
                <span className="layout-menuitem-text">{item.label}</span>
                {item.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>}
                {item.badge && <Badge value={item.badge} />}
                <Ripple />
            </>
        );

        const commonLinkProps = {
            style: item.style,
            className: classNames(item.class, 'p-ripple flex p-3 align-items-center hover:surface-50 transition-colors transition-duration-150 w-full', {
                'p-disabled': item.disabled,
                'p-link': !item.to
            }),
            target: item.target,
            onClick: (e) => onMenuItemClick(e, item, index),
            onMouseEnter: () => onMenuItemMouseEnter(index)
        };

        if (item.url) {
            return (
                <a href={item.url} rel="noopener noreferrer" {...commonLinkProps}>
                    {item.url}
                </a>
            );
        } else if (!item.to) {
            return (
                <button type="button" {...commonLinkProps}>
                    {content}
                </button>
            );
        }

        return (
            <NavLink to={item.to} {...commonLinkProps} className={({ isActive }) => classNames(commonLinkProps.className, isActive ? 'active-menuitem-routelink' : undefined)}>
                {content}
            </NavLink>
        );
    };

    const isMenuActive = (item, index) => {
        return item.items && (props.root && (!isSlim() || (isSlim() && (props.mobileMenuActive || activeIndex !== null))) ? true : activeIndex === index);
    };

    const getItems = () => {
        const transitionTimeout = props.mobileMenuActive ? 0 : isSlim() && props.root ? { enter: 0, exit: 0 } : props.root ? 0 : { enter: 1000, exit: 450 };
        return props.items.map((item, i) => {
            if (visible(item)) {
                const submenuRef = createRef();
                const menuitemClassName = classNames({
                    'layout-root-menuitem': props.root,
                    'active-menuitem': activeIndex === i && !item.disabled
                });
                const rootMenuItem = props.root && <div className="layout-menuitem-root-text">{item.label}</div>;
                const link = getLink(item, i);

                return (
                    <li key={item.label || i} className={menuitemClassName} role="menuitem">
                        {rootMenuItem}
                        {link}

                        <CSSTransition nodeRef={submenuRef} classNames="p-toggleable-content" timeout={transitionTimeout} in={isMenuActive(item, i)} unmountOnExit>
                            <AppSubmenu ref={submenuRef} items={visible(item) && item.items} menuActive={props.menuActive} layoutMode={props.layoutMode} parentMenuItemActive={activeIndex === i} onMenuItemClick={props.onMenuItemClick}></AppSubmenu>
                        </CSSTransition>
                    </li>
                );
            }

            return null;
        });
    };

    useEffect(() => {
        if (!props.menuActive && isHorizontalOrSlim()) {
            setActiveIndex(null);
        }
    }, [props.menuActive, isHorizontalOrSlim]);

    if (!props.items) {
        return null;
    }

    const items = getItems();
    return (
        <ul ref={ref} className={props.className} role="menu">
            {items}
        </ul>
    );
});

const AppMenu = (props) => {
    const btnRef1 = useRef(null);
    const btnRef2 = useRef(null);
    const btnRef3 = useRef(null);
    const btnRef4 = useRef(null);
    const btnRef5 = useRef(null);

    const { isLoggedin, logout } = useAuth();

    const navigate = useNavigate();

    const rootBtnRef = useRef(null);
    return (
        <div className=" sticky top-0  z-5 surface-overlay py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static">
            <NavLink to={`/`} className="w-full">
                <img src="assets/logo.svg" alt="hyper" height={50} />
            </NavLink>
            <StyleClass nodeRef={rootBtnRef} selector="@next" enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick>
                <a ref={rootBtnRef} className="cursor-pointer block lg:hidden text-700">
                    <i className="pi pi-bars text-4xl"></i>
                </a>
            </StyleClass>
            <div className="submenu align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 px-6 lg:px-0 z-2 shadow-2 lg:shadow-none">
                <section></section>

                <ul className="list-none p-0 m-0 flex lg:align-items-center text-900 select-none flex-column lg:flex-row cursor-pointer">
                    <li className="relative">
                        <StyleClass nodeRef={btnRef1} selector=".submenu" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                            <Link to="/locations" className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150">
                                <span ref={btnRef1}>Lokacije</span>
                                <Ripple />
                            </Link>
                        </StyleClass>
                    </li>
                    <li>
                        <StyleClass nodeRef={btnRef2} selector=".submenu" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                            <Link to="/questionnaires" className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150">
                                <span ref={btnRef2}>Anketa</span>
                                <Ripple />
                            </Link>
                        </StyleClass>
                    </li>
                    <li>
                        <StyleClass nodeRef={btnRef3} selector=".submenu" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                            <Link to="/about" className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150">
                                <span ref={btnRef3}>O nama</span>
                                <Ripple />
                            </Link>
                        </StyleClass>
                    </li>
                    <li>
                        <StyleClass nodeRef={btnRef4} selector=".submenu" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                            <Link to="/terms" className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150">
                                <span ref={btnRef4}>Uslovi korišćenja</span>
                                <Ripple />
                            </Link>
                        </StyleClass>
                    </li>
                    <li>
                        <StyleClass nodeRef={btnRef5} selector=".submenu" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                            <Link to="/privacy-policy" className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150">
                                <span ref={btnRef5}>Politika privatnosti</span>
                                <Ripple />
                            </Link>
                        </StyleClass>
                    </li>
                </ul>
                {!isLoggedin() && (
                    <div className="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
                        <Link to="/login">
                            <Button label={i18n.t('login')} className="p-button-text  p-button-outlined font-bold" />
                        </Link>
                        <Link to="/register">
                            <Button label={i18n.t('register')} className="ml-3 p-button-outlined font-bold" />
                        </Link>
                    </div>
                )}
                {isLoggedin() && (
                    <div className="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
                        <Button
                            label={i18n.t('logout')}
                            className="p-button-text  p-button-outlined font-bold"
                            onClick={(e) => {
                                logout();
                                navigate('/#');
                            }}
                        />

                        <Link to="/profile">
                            <Button label="Moj Profil" className="ml-3 p-button-outlined font-bold" />
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AppMenu;
