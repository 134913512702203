import api from '../auth';
const slug = 'api/bins';

const BinsService = () => {
    const getBinss = () => {
        return api.get(slug).then((res) => res.data);
    };

    const getBinssLazy = (params) => {
        return api
            .get(slug, {
                params
            })
            .then((res) => res.data);
    };

    const getBins = (id) => {
        return api.get(slug + `/show/${id}`).then((res) => res.data);
    };

    const getBinsByCode = (code) => {
        return api.get(slug + `/show/${code}`).then((res) => res.data);
    };

    const deleteBins = (id) => {
        return api
            .delete(slug + `/bin/${id}`)
            .then((res) => res.data)
            .catch((err) => {
                return err;
            });
    };

    const createBins = (data) => {
        return api.post(slug, data).then((res) => res.data);
    };

    const updateBins = (id, data) => {
        return api.put(slug + `/bin/${id}`, data).then((res) => res.data);
    };

    const getOptions = () => {
        return api.options(slug).then((res) => res.data.actions.POST);
    };

    // custom action
    const copyBins = (id) => {
        return api.post(slug + `/copy/${id}`).then((res) => res.data);
    };

    return {
        getBinss,
        getBinssLazy,
        getBins,
        deleteBins,
        createBins,
        updateBins,
        getOptions,
        copyBins,
        getBinsByCode
    };
};

export default BinsService;
