import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
// Import QuestionnairesService for CRUD operations
import { useAuth } from '../../hooks/auth-provider';
import QuestionnairesService from '../../service/QuestionnairesService';
import ResponsesService from '../../service/ResponsesService';

// Import all related models

// Import related store

import * as yup from 'yup';
import i18n from '../../i18n';
import { useYupValidationResolver } from '../../utilities/useYupValidationResolver';

/* Validation schema */
const validationSchema = yup.object().shape({
    naziv: yup
        .string()
        .required(i18n.t('required'))
        .max(55, i18n.t('validations.must_be_at_most', { model: 'Naziv', characters: '50' })),

    title: yup.string().required('title is required.'),
    description: yup.string().required('description is required.'),
    active_from: yup.string().required('active_from is required.'),
    active_to: yup.string().required('active_to is required.')
});
// translation with parameters

// Welcome, John, Admin

function QuestionnairesForm() {
    const [model, setModel] = useState([]);
    const [vote, setVote] = useState(0);
    const [isVote, setIsVote] = useState(false);

    const toast = useRef(null);
    const navigate = useNavigate();
    const { isLoggedin } = useAuth();

    const { id } = useParams(); // Uzimanje parametara iz URL-a

    // set default values for form
    const defaultValues = {
        title: '',
        description: '',
        active_from: '',
        active_to: ''
    };

    // related store hooks

    const { getQuestionnaires, getQuestionnairesVote } = QuestionnairesService();
    const { createResponses } = ResponsesService();

    const resolver = useYupValidationResolver(validationSchema);

    const { control, handleSubmit, reset, setFocus } = useForm({ defaultValues, resolver }); //  resolver

    // set focus on target input
    useEffect(() => {
        setFocus('naziv');
    }, [setFocus]);

    useEffect(() => {
        if (id) {
            getQuestionnaires(id).then((res) => {
                setModel(res.data);
                reset(res.data);
            });
            getQuestionnairesVote(id).then((res) => {
                console.log(res.data.voted);
                setIsVote(res.data.voted);
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = (data) => {
        if (vote) {
            createResponses({ question_id: vote, questionnaire: id, is_valid: 1 })
                .then((res) => {
                    toast.current.show({ severity: 'success', summary: i18n.t('congrat'), detail: i18n.t('vote_successfully'), life: 3000 });
                    setIsVote(true);
                    // navigate('/');
                })
                .catch((err) => {
                    toast.current.show({ severity: 'error', summary: i18n.t('error'), detail: i18n.t('error_occured'), life: 3000 });
                });
        } else {
            toast.current.show({ severity: 'error', summary: i18n.t('error'), detail: 'Morate odabrati jednu opciju', life: 3000 });
        }
    };

    // render checkbox

    return (
        <div className="form-questionnaires">
            <Toast ref={toast} />
            <div className=" p-4">
                <div className="grid">
                    <div className="col-12 ">
                        <span className="text-900 text-2xl font-medium mb-4 block">{model.title}</span>

                        <span className="text-700 text-lg font-medium mb-4 block">{model.description}</span>

                        <form onSubmit={handleSubmit(onSubmit)} className="">
                            {/* map to questions list and render checkbox */}

                            <div className="w-full">
                                <Controller
                                    name="value"
                                    control={control}
                                    rules={{ required: 'Value is required.' }}
                                    render={({ field }) => (
                                        <>
                                            {model.questions &&
                                                model.questions.map((question) => {
                                                    return (
                                                        <div
                                                            key={question.id}
                                                            className={classNames(' border-round-xl my-2 surface-card border-2 p-3 flex align-items-center cursor-pointer ', {
                                                                'surface-border': vote !== question.id,
                                                                'border-primary': vote === question.id
                                                            })}
                                                            onClick={() => setVote(question.id)}
                                                        >
                                                            <RadioButton name="vote" value={question.id} onChange={(e) => setVote(e.value)} checked={vote === question.id} className="mr-3" />
                                                            <div className="mr-4 md:mr-8">
                                                                <div className="font-medium mb-1">{question.options}</div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </>
                                    )}
                                />
                            </div>
                            <hr />
                            {isLoggedin() && (
                                <div className="flex justify-content-between flex-wrap">
                                    {!isVote && <Button className=" p-button-rounded  px-5 py-3 p-button-raised" icon="pi pi-save" onClick={(e) => onSubmit(e)} label={id ? i18n.t('vote') : i18n.t('add')} type="submit" severity="success" />}
                                    {isVote && <div className="flex align-items-start mb-3 p-4 bg-green-100 border-round border-1 border-green-300">Vaš glas je uspešno evidentiran za ovo glasanje!</div>}
                                    {id && (
                                        <Button icon="pi pi-thumbs-up" label={i18n.t('go_to_question')} type="button" className="  p-button-rounded  px-5 py-3 p-button-raised  mr-1" severity="warning" onClick={() => navigate('/questionnaires')} />
                                    )}
                                </div>
                            )}
                            {!isLoggedin() && (
                                <div className=" ">
                                    <div>
                                        <div className="flex align-items-start mb-3 p-4 bg-green-100 border-round border-1 border-green-300">{i18n.t('login_to_continue')}</div>
                                    </div>
                                    <div className="flex justify-content-between flex-wrap">
                                        <Link to="/login" className=" p-button-text text-green-900 hover:text-green-700">
                                            <Button icon="pi pi-user" className="w-full p-button-rounded  px-5 py-3 p-button-raised" label={i18n.t('login')} type="submit" />
                                        </Link>
                                        <Link to="/register" className=" text-green-900 hover:text-green-700">
                                            <Button icon="pi pi-user-plus" className="w-ful p-button-text  p-button-rounded  px-5 py-3 p-button-raised" label={i18n.t('register')} type="submit" />
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>

            {/* <pre>{JSON.stringify(model, null, 2)}</pre> */}
        </div>
    );
}

export default QuestionnairesForm;
