import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/auth-provider';

function GoogleCallback() {
    const [user, setUser] = useState(null);
    const location = useLocation();

    const { googleCallback } = useAuth();

    // On page load, we take "search" parameters
    // and proxy them to /api/auth/callback on our Laravel API
    useEffect(() => {
        googleCallback(location.search)
            .then((data) => {
                setUser(data);
                window.location.href = '/';
            })
            .catch((error) => {
                console.error(error);
            });
    }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

    if (user != null) {
        return <DisplayData data={user} />;
    } else {
        return (
            <div className="p-4">
                <h1>Uspešno ste ulogovani!</h1>
                <p>Redirektovaćemo vas na početnu stranicu...</p>
            </div>
        );
    }
}

function DisplayData(data) {
    return (
        <div>
            <samp>{JSON.stringify(data, null, 2)}</samp>
        </div>
    );
}

export default GoogleCallback;
