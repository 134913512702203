import api from '../auth';
const slug = 'api/responses';

const ResponsesService = () => {
    const getResponsess = () => {
        return api.get(slug).then((res) => res.data);
    };

    const getResponsessLazy = (params) => {
        return api
            .get(slug, {
                params
            })
            .then((res) => res.data);
    };

    const getResponses = (id) => {
        return api.get(slug + `/show/${id}`).then((res) => res.data);
    };

    const deleteResponses = (id) => {
        return api
            .delete(slug + `/response/${id}`)
            .then((res) => res.data)
            .catch((err) => {
                return err;
            });
    };

    const createResponses = (data) => {
        return api.post(slug, data).then((res) => res.data);
    };

    const updateResponses = (id, data) => {
        return api.put(slug + `/response/${id}`, data).then((res) => res.data);
    };

    const getOptions = () => {
        return api.options(slug).then((res) => res.data.actions.POST);
    };

    // custom action
    const copyResponses = (id) => {
        return api.post(slug + `/copy/${id}`).then((res) => res.data);
    };

    return {
        getResponsess,
        getResponsessLazy,
        getResponses,
        deleteResponses,
        createResponses,
        updateResponses,
        getOptions,
        copyResponses
    };
};

export default ResponsesService;
