import React from 'react';

const Terms = () => {
    return (
        <div className="card">
            <h1>Opšti uslovi korišćenja</h1>
            <div className="text-base">Dobrodošli na našu aplikaciju za reciklažu otpadnog ulja. Korišćenjem ove aplikacije, prihvatate sledeće uslove korišćenja:</div>
            <ol>
                <li>Aplikacija je namenjena isključivo za prijavu i praćenje količine odloženog otpadnog ulja.</li>
                <li>Korisnici su odgovorni za tačnost i istinitost unetih informacija o količini ulja koje su odložili.</li>
                <li>Korisnici imaju pravo da glasaju za određene ankete koje se tiču reciklaže otpadnog ulja.</li>
                <li>Korisnici se obavezuju da neće zloupotrebljavati aplikaciju i da će je koristiti u skladu sa propisima i zakonima.</li>
                <li>Aplikacija ne snosi odgovornost za eventualne štete ili gubitke koji mogu nastati korišćenjem ove aplikacije.</li>
            </ol>
        </div>
    );
};

export default Terms;
