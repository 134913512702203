// About us page  (src/pages/About.js)

import React from 'react';

const About = () => {
    return (
        <div className="card">
            <h1>O nama</h1>
            <p>Tekst o nama...</p>
            <p>V 1.0</p>
        </div>
    );
};

export default About;
