import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Ripple } from 'primereact/ripple';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAuth } from '../hooks/auth-provider';
import { useYupValidationResolver } from '../utilities/useYupValidationResolver';

const schema = yup.object().shape({
    username: yup.string().required('Korisničko ime je obavezno polje'),
    email: yup.string().required('Email je obaveyno polje').email('Neispravan email'),
    password: yup.string().required('Lozinka je obaveyno polje').min(6, 'Lozinka mora biti najmanje 6 karaktera')
});

export const Register = () => {
    const { singin, getAuthUser } = useAuth();
    const [loginUrl, setLoginUrl] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: useYupValidationResolver(schema)
    });

    const navigate = useNavigate();

    useEffect(() => {
        getAuthUser()
            .then((data) => {
                console.log('url', data);
                setLoginUrl(data.url);
            })
            .catch((error) => console.error(error));
    }, []); // eslint-disable-line

    const handleLogin = async (data) => {
        singin(data.username, data.email, data.password, data.passwordConfirmation)
            .then(() => {
                navigate('/');
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="login-page px-4 md:px-6 lg:px-8 flex align-items-center justify-content-center">
            <div className="w-full lg:w-6 p-4 lg:p-7 ">
                <Link to={'/'}>
                    <div className="login-header w-full flex align-items-center justify-content-center mb-2 px-2 pt-2">
                        <div className="login-header__logo ">
                            <img src="assets/logo_full.svg" alt="logo" width={120} />
                        </div>
                    </div>
                </Link>
                <div className="flex justify-content-center align-items-center mb-7  text-center ">
                    <div className="text-2xl  text-center  font-medium text-900">Kreiraj nalog za ecOil</div>
                </div>
                {loginUrl != null && (
                    <a href={loginUrl}>
                        <div className="flex justify-content-center">
                            <button className="p-ripple ml-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
                                <i className="pi pi-google text-pink-500 mr-2"></i>
                                <span>Uloguj se putem Googla</span>
                                <Ripple />
                            </button>
                        </div>
                    </a>
                )}

                <Divider align="center" className="my-4">
                    <span className="text-600 font-normal text-sm">ILI</span>
                </Divider>
                <form onSubmit={handleSubmit(handleLogin)}>
                    <label htmlFor="username" className="block text-900 font-medium mb-2">
                        Korisničko ime
                    </label>
                    <InputText id="username" type="text" placeholder="Korisničko ime" {...register('username')} className="w-full mb-3 p-3" />
                    {errors.username && <p>{errors.username.message}</p>}
                    {/* ... */}
                    <label htmlFor="email" className="block text-900 font-medium mb-2">
                        Email
                    </label>
                    <InputText id="email" type="text" placeholder="Email address" {...register('email')} className="w-full mb-3 p-3" />
                    {errors.email && <p>{errors.email.message}</p>}
                    {/* ... */}
                    <label htmlFor="password" className="block text-900 font-medium mb-2">
                        Lozinka
                    </label>
                    <InputText id="password" type="password" placeholder="Lozinka" {...register('password')} className="w-full mb-3 p-3" />
                    {errors.password && <p>{errors.password.message}</p>}
                    {/* ... */}

                    <label htmlFor="password" className="block text-900 font-medium mb-2">
                        Potvrdi lozinku
                    </label>
                    <InputText id="passwordConfirmation" type="password" placeholder="Ponovljana lozinka" {...register('passwordConfirmation')} className="w-full mb-3 p-3" />

                    <Button label="Ulaz" type="submit" className="w-full py-3 font-medium" />
                </form>
                <div className="mt-4 text-center text-900 font-medium">
                    <Link to="/login" className="text-xl text-green-500 hover:text-green-700 cursor-pointer transition-colors transition-duration-150">
                        Već imate nalog?
                    </Link>
                </div>
            </div>
        </div>
    );
};
