import api from '../auth';
const slug = 'api/questionnaires';

const QuestionnairesService = () => {
    const getQuestionnairess = () => {
        return api.get(slug).then((res) => res.data);
    };

    const getQuestionnairessLazy = (params) => {
        return api
            .get(slug, {
                params
            })
            .then((res) => res.data);
    };

    const getQuestionnaires = (id) => {
        return api.get(slug + `/show/${id}`).then((res) => res.data);
    };

    const getQuestionnairesVote = (id) => {
        return api.get(slug + `/vote/${id}`).then((res) => res.data);
    };

    const getQuestionnairessActive = (id) => {
        return api.get(slug + `/active`).then((res) => res.data);
    };

    const deleteQuestionnaires = (id) => {
        return api
            .delete(slug + `/questionnaire/${id}`)
            .then((res) => res.data)
            .catch((err) => {
                return err;
            });
    };

    const createQuestionnaires = (data) => {
        return api.post(slug, data).then((res) => res.data);
    };

    const updateQuestionnaires = (id, data) => {
        return api.put(slug + `/questionnaire/${id}`, data).then((res) => res.data);
    };

    const getOptions = () => {
        return api.options(slug).then((res) => res.data.actions.POST);
    };

    // custom action
    const copyQuestionnaires = (id) => {
        return api.post(slug + `/copy/${id}`).then((res) => res.data);
    };

    return {
        getQuestionnairess,
        getQuestionnairessLazy,
        getQuestionnairessActive,
        getQuestionnaires,
        getQuestionnairesVote,
        deleteQuestionnaires,
        createQuestionnaires,
        updateQuestionnaires,
        getOptions,
        copyQuestionnaires
    };
};

export default QuestionnairesService;
