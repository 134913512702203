import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="card">
            <h1>Politika privatnost</h1>

            <div className="text-base">
                Ova politika privatnosti objašnjava kako se koristi i štiti bilo koja informacija koju pružite prilikom korišćenja našeg sajta.
                <br />
                <br />
                <strong>Kreiranje korisničkog naloga:</strong>
                <br />
                Prilikom kreiranja korisničkog naloga, tražićemo od vas da pružite određene lične informacije, uključujući vaše ime i e-mail adresu. Ove informacije će biti korišćene isključivo za potrebe identifikacije i pružanja usluga našeg sajta.
                Vaše informacije o korisničkom nalogu neće biti deljene ni sa kim.
                <br />
                <br />
                <strong>Anketa:</strong>
                <br />
                Na našem sajtu može biti dostupna anketa koju članovi mogu popuniti. Rezultati ankete će biti korišćeni isključivo za brojanje glasova i poboljšanje naših usluga. Vaše lične informacije neće biti povezane sa rezultatima ankete.
                <br />
                <br />
                Vaša privatnost je od velike važnosti za nas i preduzećemo sve razumne korake da zaštitimo vaše lične informacije. Ukoliko imate bilo kakva pitanja ili nedoumice u vezi sa našom politikom privatnosti, slobodno nas kontaktirajte.
            </div>
        </div>
    );
};

export default PrivacyPolicy;
