import React from 'react';
import { QrReader } from 'react-qr-reader';

export const QrScaner = (props) => {
    // const [data, setData] = useState('No result');

    return (
        <QrReader
            constraints={{
                facingMode: 'environment'
            }}
            onResult={(result, error) => {
                if (!!result) {
                    props.onScane(result?.text);
                }

                if (!!error) {
                    console.info('error', error);
                }
            }}
            style={{ width: '100%' }}
        />
    );
};
